@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope";
  scroll-behavior: smooth;
}

.nav-links .active {
  color: rgba(59, 125, 221, 1);
  border-bottom: 6px solid rgba(59, 125, 221, 1);
}

.privacy-policy > p {
  margin-top: 0;
  margin-bottom: 1em;
}
